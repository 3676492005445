import ArgoIcon from "./ArgoIcon";
import MsSqlServerIcon from "./MsSqlServerIcon";
import OracleIcon from "./OracleIcon";
import PostgreSqlIcon from "./PostgreSqlIcon";
import MySqlIcon from "./MySqlIcon";
import AwsIcon from "./AwsIcon";
import AzureIcon from "./AzureIcon";

export const Argo = ArgoIcon;
export const Aws = AwsIcon;
export const Azure = AzureIcon;
export const MsSqlServer = MsSqlServerIcon;
export const Oracle = OracleIcon;
export const PostgreSql = PostgreSqlIcon;
export const MySql = MySqlIcon;
