/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import YAML from "yaml";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import DataGridButton from "../../../components/DataGridButton";
import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useListAllControlPlanes } from "../../../api/controlPlanes";
import { timeFormat } from "../../../utils/constants";
import { dataGridButtonTypography } from "../../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../../styles/buttonStyles";
import { tabbedDataGridPage } from "../../../styles/commonPageStyles";
import { Aws, Azure } from "../../../icons";
import { Info } from "@mui/icons-material";

const styles = {
  RmoTypography: { whiteSpace: "pre-wrap", fontFamily: "monospace" },
};

const ControlPlanes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { deploymentStage } = useParams();

  const [controlPlanes, setControlPlanes] = useState([]);

  const {
    postData: listAllControlPlanes,
    response: listAllControlPlanesResp,
    isLoading: isLoadingControlPlanes,
  } = useListAllControlPlanes(deploymentStage);

  useEffect(() => {
    listAllControlPlanes();
  }, [deploymentStage]);

  useEffect(() => {
    if (Array.isArray(listAllControlPlanesResp?.controlPlanes)) {
      setControlPlanes(listAllControlPlanesResp.controlPlanes);
    } else {
      setControlPlanes([]);
    }
  }, [listAllControlPlanesResp]);

  const columns = [
    {
      field: "name",
      renderHeader: () => <Typography variant="h5">Name</Typography>,
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { name } }) => (
        <Typography variant="h5">{name || ""}</Typography>
      ),
    },
    {
      field: "releaseLabel",
      flex: 1,
      renderHeader: () => <Typography variant="h5">Release Label</Typography>,
      renderCell: ({ row: { releaseLabel, releaseManifestOverride } }) => (
        <>
          <Typography variant="h5">{releaseLabel ?? ""}</Typography>
          {releaseManifestOverride ? (
            <Tooltip
              title={
                <Typography variant="body2" sx={styles.RmoTypography}>
                  {YAML.stringify(
                    { override: releaseManifestOverride },
                    null,
                    2
                  )}
                </Typography>
              }
            >
              <Info fontSize="small" />
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      field: "k8sClusterName",
      flex: 2,
      renderHeader: () => <Typography variant="h5">K8s Cluster</Typography>,
      renderCell: ({ row: { k8sClusterName } }) => (
        <Typography variant="h5">{k8sClusterName ?? ""}</Typography>
      ),
    },
    {
      field: "cloud",
      flex: 1,
      renderHeader: () => <Typography variant="h5">Cloud</Typography>,
      renderCell: ({ row: { cloud } }) => {
        switch (cloud) {
          case "AWS":
            return <Aws />;
          case "AZURE":
            return <Azure />;
          default:
            return <Typography variant="h5">{cloud ?? ""}</Typography>;
        }
      },
    },
    {
      field: "region",
      flex: 1,
      renderHeader: () => <Typography variant="h5">Region</Typography>,
      renderCell: ({ row: { region } }) => (
        <Typography variant="h5">{region ?? ""}</Typography>
      ),
    },
    {
      field: "deploymentStatus",
      flex: 1,
      renderHeader: () => <Typography variant="h5">Status</Typography>,
      renderCell: ({ row: { deploymentStatus } }) => (
        <Typography variant="h5">{deploymentStatus ?? ""}</Typography>
      ),
    },
    {
      field: "dateModified",
      flex: 2,
      renderHeader: () => <Typography variant="h5">Date Modified</Typography>,
      renderCell: ({ row: { dateModified } }) => (
        <Typography variant="h5">
          {moment(dateModified).format(timeFormat)}
        </Typography>
      ),
    },
  ];

  return (
    <Box sx={tabbedDataGridPage.tabContentMainBox}>
      <Grid sx={tabbedDataGridPage.gridContainer} container columns={2}>
        <Grid xs={1}>
          <Header
            title="Tessell Control Planes"
            subtitle={`See the list ${deploymentStage} control planes`}
          />
        </Grid>
        <Grid xs={1}>
          <Box sx={tabbedDataGridPage.topButtonStack}>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => listAllControlPlanes()}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
          </Box>
        </Grid>
      </Grid>
      <Box sx={tabbedDataGridPage.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          getRowId={(row) => row?.name}
          loading={isLoadingControlPlanes}
          rows={controlPlanes}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default ControlPlanes;
